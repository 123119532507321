.top-sidebar img {
  margin: 10px;
}

.top-sidebar select {
  max-width: 94%;
  margin: 9px;
  padding: 10px;
  font-size: 12pt;
  font-weight: bold;
  border-radius: 5px;
  background-color: #232425;
  color: rgba(255, 255, 255, 0.929);
  cursor: pointer;
}
